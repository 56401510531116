import React, { Component } from "react";

class About extends Component {
  render() {
    return (
      <div>
        <h3 align="center"> Hey there!  👩🏽‍💻 👋🏽</h3>

        <p> My name is <b>Ívina</b> (reads Eevnah). I'm a fresh software engineer based in London 🇬🇧 with roots in the Netherlands 🇳🇱 and Brazil 🇧🇷. </p>

        <ul>
          <li>👩🏽‍🎨 I build amazing things at Capgemini. </li>
          <li>💻 I am passionate about technology, computers (and how they work) and coffee!</li>
          <li>🎓 Graduated from @CodeYourFuture.</li>
          <li>🌱 I’m currently learning Node.js and TDD.</li>
          <li>🎨 I draw doodles as hobby </li>
        </ul>

        <p align="center" >Say hi: ✨</p>

      </div>
    );
  }
}

export default About;
